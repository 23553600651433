import React from 'react';
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <a href="/Impressum">Impressum</a>
    </div>
  );
}
